import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import Slick from "react-slick"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const SliderWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;

  div {
    width: 100%;
    height: 100%;
    div {
      width: 100%;
      height: 100%;
      div {
        width: 100%;
        height: 100%;
        div {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
`

const SliderItem = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
`

const SliderImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const Slider = ({ images }) => {
  const {
    allContentfulSlider: { edges: data },
  } = useStaticQuery(graphql`
    query {
      allContentfulSlider {
        edges {
          node {
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    pauseOnFocus: false,
  }

  return (
    <SliderWrapper>
      <Slick {...sliderSettings}>
        {!images
          ? data.map(({ node }) => (
              <SliderItem key={node.image.file.url}>
                <SliderImage src={node.image.file.url} alt="" />
              </SliderItem>
            ))
          : images.map(item => (
              <SliderItem key={item.file.url}>
                <SliderImage src={item.file.url} alt="" />
              </SliderItem>
            ))}
      </Slick>
    </SliderWrapper>
  )
}
