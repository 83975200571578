import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import styled, { css } from "styled-components"

import MenuSrc from "../../../assets/menu.svg"
import CloseSrc from "../../../assets/close.svg"
import { Container } from "../../Layout/components/Container"
import { media } from "../../../utils/media"

const NavigationWrapper = styled.nav`
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;

  ${media.md`
    width: 100%;
  `}
`

const NavigationTrigger = styled.button`
  width: 35px;
  height: 35px;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;

  ${media.md`
    display: none;
    width: 0;
    height: 0;
    opacity: 0;
  `}
`

const NavigationTriggerImage = styled.img`
  margin: 0;
  padding: 0;
`

const NavigationCloseButton = styled(NavigationTrigger)`
  position: absolute;
  right: 0;
  top: -1rem;
  width: 30px;
  height: 30px;

  ${media.md`
    display: none;
    width: 0;
    height: 0;
    opacity: 0;
  `}
`

const NavigationListWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #fafafa;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  padding: 3rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  transform: translateY(-100%);
  ${props =>
    props.isOpen &&
    css`
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
    `};
  transition: 0.5s;

  ${media.md`
    position: static;
    padding: 0;
    opacity: 1;
    visibility: visible;
    transform: none;
  `}
`

const NavigationListContainer = styled(Container)`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const NavigationList = styled.ul`
  width: 100%;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
  text-transform: uppercase;

  ${media.md`
    flex-direction: row;
    justify-content: flex-end;
  `}
`

const NavigationListItem = styled.li`
  width: 100%;
  margin-bottom: 1rem;
  text-align: center;

  ${media.md`
    width: auto;
    margin-bottom: 0;
  `}
`

const NavigationLink = styled(Link)`
  padding: 0.3rem 0.5rem;
  border-radius: 3px;
  background-image: none;
  color: #333;
  transition: background 0.3s;

  ${media.md`
    font-size: .75rem;
  `}

  &:hover {
    background-color: #aa0000;
    color: #fff;

    ${media.md`
      background-color: #fafafa;
      color: #333;
    `}
  }
`

export const Navigation = () => {
  const {
    allContentfulPages: { edges: dynamicNavItems },
  } = useStaticQuery(graphql`
    query {
      allContentfulPages {
        edges {
          node {
            slug
            title
          }
        }
      }
    }
  `)

  const [navState, setNavState] = useState(false)

  const toggleNav = () => {
    setNavState(!navState)
  }

  const staticNavItems = [
    {
      name: "Strona główna",
      path: "/",
    },
    {
      name: "Inwestycje",
      path: "/invests",
    },
  ]

  return (
    <NavigationWrapper>
      <NavigationTrigger
        onClick={toggleNav}
        aria-label="Otwórz/zamknij nawigację"
      >
        <NavigationTriggerImage src={MenuSrc} alt="" aria-hidden="true" />
      </NavigationTrigger>
      <NavigationListWrapper isOpen={navState}>
        <NavigationListContainer>
          <NavigationCloseButton onClick={toggleNav}>
            <NavigationTriggerImage src={CloseSrc} alt="" aria-hidden="true" />
          </NavigationCloseButton>
          <NavigationList>
            {staticNavItems.map((item, index) => (
              <NavigationListItem key={index}>
                <NavigationLink to={item.path}>{item.name}</NavigationLink>
              </NavigationListItem>
            ))}
            {dynamicNavItems.map(({ node }) => (
              <NavigationListItem key={node.slug}>
                <NavigationLink to={`/${node.slug}`}>
                  {node.title}
                </NavigationLink>
              </NavigationListItem>
            ))}
            <NavigationListItem>
              <NavigationLink to={`/contact`}>Kontakt</NavigationLink>
            </NavigationListItem>
          </NavigationList>
        </NavigationListContainer>
      </NavigationListWrapper>
    </NavigationWrapper>
  )
}
