import React from "react"
import styled from "styled-components"
import { Container } from "../Layout/components/Container"
import { Row, Col } from "reactstrap"

import eu1 from "../../assets/eu1.jpg"
import eu2 from "../../assets/eu2.png"
import eu3 from "../../assets/eu3.jpg"
import eu4 from "../../assets/eu4.png"

const FooterWrapper = styled.footer`
  width: 100%;
  margin-bottom: 50vh;
  background: #fafafa;
`

const FooterRow = styled(Row)`
  margin-top: 2rem;
  padding: 2rem 0;
`

const ServicesList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`

const ServicesItem = styled.li`
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #95afc0;
`

const ServicesLink = styled.a`
  font-size: 1rem;
  color: #333;
  text-decoration: none;
  background-image: none;
`

const Map = styled.div`
  display: block;
  width: 100%;
  pointer-events: none;
`
const UEContainer = styled.div`
  background: #fff;
  width: 100%;
  padding: 2rem 0 0 0;
  margin-top: -2rem;

  div {
    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  img {
    width: 50%;
  }
`

export const Footer = () => {
  return (
    <FooterWrapper>
      <Container>
        <FooterRow>
          <Col sm="12" md="6">
            <h4>Siedziba firmy</h4>
            <address>
              <strong>
                Przedsiębiorstwo Budowlano-Handlowo-Transportowe "Edmund Leś"
              </strong>
              <br />
              32-800 Brzesko, ul.Solskiego 14a
              <br />
              tel: (14) 68 63 370
              <br />
              NIP: 869-000-16-29
            </address>
          </Col>
          <Col sm="12" md="6">
            <h4>Nasze serwisy</h4>
            <ServicesList>
              <ServicesItem>
                <ServicesLink
                  href="http://psbles.com"
                  rel="noopener norefferer"
                  target="_blank"
                >
                  PSB Leś
                </ServicesLink>
              </ServicesItem>
              <ServicesItem>
                <ServicesLink
                  href="http://dzwignij.pl"
                  rel="noopener norefferer"
                  target="_blank"
                >
                  Dźwignij
                </ServicesLink>
              </ServicesItem>
              <ServicesItem>
                <ServicesLink
                  href="http://wypozyczalnia-les.pl"
                  rel="noopener norefferer"
                  target="_blank"
                >
                  Wypożyczalnia sprzętu
                </ServicesLink>
              </ServicesItem>
            </ServicesList>
          </Col>
        </FooterRow>
      </Container>
      <Map>
        <iframe
          title="Map"
          scrollwheel="false"
          width="100%"
          height="350"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          src="https://www.openstreetmap.org/export/embed.html?bbox=20.615284144878387%2C49.971980731862054%2C20.6175372004509%2C49.97330213673347&amp;layer=mapnik&amp;marker=49.97264132427771%2C20.616410421207547"
        ></iframe>
      </Map>
      <UEContainer>
        <Container>
          <Row>
            <Col sm="12" md="3">
              <img src={eu1} alt="" />
            </Col>
            <Col sm="12" md="3">
              <img src={eu2} alt="" />
            </Col>
            <Col sm="12" md="3">
              <img src={eu3} alt="" />
            </Col>
            <Col sm="12" md="3">
              <img src={eu4} alt="" />
            </Col>
          </Row>
        </Container>
      </UEContainer>
    </FooterWrapper>
  )
}
