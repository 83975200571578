import React, { useState, useEffect } from "react"
import { Toast, ToastBody, Button } from "reactstrap"
import styled from "styled-components"

const CookiesWrapper = styled.div`
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  z-index: 9998;
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 1rem;

  button {
    margin-right: 1rem;
  }
`

export const Cookies = () => {
  const [showCookiesInfo, setState] = useState(false)

  const checkIfAccepted = () => {
    const lsItem = localStorage.getItem("cookies_info_accepted")

    if (!lsItem) {
      setState(true)
    } else {
      setState(false)
    }
  }

  useEffect(() => {
    checkIfAccepted()
  })

  const acceptCookies = () => {
    localStorage.setItem("cookies_info_accepted", true)
    setState(false)
  }

  const rejectCookies = () => {
    window.location.replace("https://google.com")
  }

  return (
    showCookiesInfo && (
      <CookiesWrapper className="p-3 my-2 rounded bg-docs-transparent-grid">
        <Toast>
          <ToastBody>
            Ta strona używa cookies, localStorage oraz sessionStorage w celach
            statystycznych.
            <ButtonContainer>
              <Button outline color="primary" size="sm" onClick={acceptCookies}>
                Akceptuje
              </Button>
              <Button outline color="danger" size="sm" onClick={rejectCookies}>
                Odrzucam
              </Button>
            </ButtonContainer>
          </ToastBody>
        </Toast>
      </CookiesWrapper>
    )
  )
}
