import React from "react"
import styled from "styled-components"
import { Container } from "../Layout/components/Container"
import { Logo } from "./Logo/Logo"
import { Navigation } from "./Navigation/Navigation"

const HeaderWrapper = styled.header`
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  background: #fafafa;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  z-index: 9999;
`

const HeaderContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 0.5rem 1rem;
`

export const Header = () => {
  return (
    <HeaderWrapper>
      <HeaderContainer>
        <Logo />
        <Navigation />
      </HeaderContainer>
    </HeaderWrapper>
  )
}
