import React from "react"
import styled from "styled-components"
import LogoSrc from "../../../assets/logo.png"
import { Link } from "gatsby"

const LogoWrapper = styled.div`
  margin-right: 1rem;
  height: 35px;
`

const LogoImg = styled.img`
  width: auto;
  height: 100%;
  margin-bottom: 0;
`

const LogoLink = styled(Link)`
  background-image: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`

export const Logo = () => {
  return (
    <LogoWrapper>
      <LogoLink to="/">
        <LogoImg src={LogoSrc} alt="P.B.H.T Edmund Leś" />
      </LogoLink>
    </LogoWrapper>
  )
}
