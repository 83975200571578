import React from "react"
import styled, { css } from "styled-components"
import { Header } from "../Header/Header"
import { Container } from "./components/Container"

import "bootstrap/dist/css/bootstrap.min.css"
import { Footer } from "../Footer/Footer"
import { Cookies } from "../Cookies"

const LayoutWrapper = styled.div`
  position: relative;
`

const MainWrapper = styled.main`
  width: 100%;
  margin-top: 70vh;
  ${props =>
    props.homepage &&
    css`
      margin-top: 90vh;
    `}
`

const MainContainer = styled(Container)`
  background: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  padding: 1rem 2rem;
`

export const Layout = ({ children, homepage }) => {
  return (
    <LayoutWrapper>
      <Header />
      <MainWrapper homepage={homepage}>
        <MainContainer>{children}</MainContainer>
      </MainWrapper>
      <Footer />
      <Cookies />
    </LayoutWrapper>
  )
}
