import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  max-width: 1024px;
  height: auto;
  margin: 0 auto;
  padding: 0 1rem;
  position: relative;
`
